import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import Vuex from 'vuex'
//import './index.css'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import Multiselect from 'vue-multiselect';
//import Vue3Datepicker from 'vue3-datepicker'
import VueQRCodeComponent from 'vue-qrcode-component'

const app = createApp(App)
app.component('qr-code', VueQRCodeComponent)
app.use(store);
app.use(router);
app.use(Multiselect);
app.use(Vuex)
app.use(VueSweetalert2);
//app.use(Vue3Datepicker)
app.mount('#app');






