<template>
    

<html lang="en" data-layout="horizontal" data-layout-style="" data-layout-position="fixed" data-topbar="light">

<div id="layout-wrapper">

<header id="page-topbar" style="background-color: #fff;">
<div class="layout-width">
<div class="navbar-header">
    
    <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-expand-lg navbar-light bg-ligh align-items-center">
          
            <a href="index.html" class="logo ">
                <span class="logo-sm">
                    <img src="@/assets/img/logolabai.png" alt="">
                </span>
                <span class="logo-lg">
                    <img src="@/assets/img/logolabai.png" alt="" >
                </span>
            </a>
        </div>

        <!--start-->
        <div class="d-flex align-items-center">

        

        <div class="dropdown ms-sm-3 header-item topbar-user" style="background-color: #fff">
            <button type="button" class="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="d-flex align-items-center">

                    <span class="text-start ms-xl-2">
                        <span class="d-none d-xl-inline-block ms-1 fw-medium user-name-text"></span>
                        
                    </span>
                </span>
            </button>
            
        </div>
    </div>
        <!-- App Search-->
        
    </div>

    
</div>
</div>
</header>

<!-- removeNotificationModal -->
<div id="removeNotificationModal" class="modal fade zoomIn" tabindex="-1" aria-hidden="true">
<div class="modal-dialog modal-dialog-centered">
<div class="modal-content">
    <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="NotificationModalbtn-close"></button>
    </div>
    <div class="modal-body">
        <div class="mt-2 text-center">
            <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop" colors="primary:#f7b84b,secondary:#f06548" style="width:100px;height:100px"></lord-icon>
            <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                <h4>Are you sure ?</h4>
                <p class="text-muted mx-4 mb-0">Are you sure you want to remove this Notification ?</p>
            </div>
        </div>
        <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button type="button" class="btn w-sm btn-light" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn w-sm btn-danger" id="delete-notification">Yes, Delete It!</button>
        </div>
    </div>

</div><!-- /.modal-content -->
</div><!-- /.modal-dialog -->
</div><!-- /.modal -->
<!-- ========== App Menu ========== -->

<!-- ========================================================================================== -->
<Nav />
<!-- ========================================================================================== -->

<!-- Left Sidebar End -->
<!-- Vertical Overlay-->
<div class="vertical-overlay"></div>

<!-- ============================================================== -->
<!-- Start right Content here -->
<!-- ============================================================== -->
<div class="main-content">

    <div class="page-content" style="margin-top: -80px;">
        <div class="container-fluid">

            <div class="profile-foreground position-relative mx-n4 mt-n4">
                        <div class="profile-wid-bg">
                            
                            <img src="assets/img/wheat-2391348_1280.jpg" alt="" class="profile-wid-img" >
                        </div>
            </div>
            
            <main>
                <router-view></router-view>
             </main>


        </div>
        <!-- container-fluid -->
    </div>
    <!-- End Page-content -->

    <!-- ========================================================================================== -->
    <Footer />
    <!-- ========================================================================================== -->
    
</div>
<!-- end main content-->

</div>


    
</html>
    
    
</template>



<script>
import Nav from '@/components/frontend/Nav.vue'
import Footer from '@/components/frontend/Footer.vue'

export default {
    name : 'MainFontendlayout',
    components :{

        Nav,
        Footer
    }
    
}
</script>