<template>
  <!-- <div class="card-body">
    <h1>ข้อมูลผู้ใช้</h1>
    <div v-if="user">
      <p><strong>ชื่อ:</strong> {{ member_id }}</p>
      <p><strong>Email:</strong> {{ user.token }}</p>
      
    </div>
    <div v-else>
      <p>ไม่มีข้อมูลผู้ใช้</p>
    </div>
  </div> -->



  <div class="row" style="padding-top: 50px;">
                        <div class="col-xxl-3">
                            <div class="card mt-n5">
                                <div class="card-body p-4">
                                    <div class="text-center">
                                        <div class="profile-user position-relative d-inline-block mx-auto  mb-4">

                                            <div class="border p-2 mt-3">
                                        
                                                <template v-if="preview">
                                                    <img :src="preview" class="img-fluid" />
                                                    <!-- <p class="mb-0">file name: {{ image.name }}</p>
                                                    <p class="mb-0">size: {{ image.size/1024 }}KB</p> -->
                                                </template>
                                            </div>
                                            <!-- <img src="<?PHP echo url('/'); ?>/public/assets/images/users/avatar-3.jpg" class="rounded-circle avatar-xl img-thumbnail user-profile-image" alt="user-profile-image"> -->
                                            <div class="avatar-xs p-0 rounded-circle profile-photo-edit">
                                                <input ref="fileInput" accept="image/*" name="avatar" @change="previewImage" id="profile-img-file-input" type="file" class="profile-img-file-input">
                                                
                                                
                                                <label for="profile-img-file-input" class="profile-photo-edit avatar-xs">

                                                    
                                                    <span class="avatar-title rounded-circle bg-light text-body">
                                                        
                                                        <i class="ri-camera-fill"></i>
                                                    </span>
                                                </label>
                                            </div>
                                            <div id="img_error_message" style="color: red;"></div>
                                        </div>

                                        
    
                                       
                                        <h5 class="fs-16 mb-1">{{ member.first_name }} {{ member.last_name }}</h5>
                                        <!-- <p class="text-muted mb-0">........</p> -->
                                    </div>
                                </div>
                            </div>
                            
                           
                        </div>
                        <!--end col-->
                        <div class="col-xxl-9">
                            <div class="card mt-xxl-n5">
                                <div class="card-header">
                                    <ul class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <a class="nav-link active" data-bs-toggle="tab" href="#personalDetails" role="tab" aria-selected="true">
                                                <i class="fas fa-home"></i> ข้อมูลส่วนตัว
                                            </a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a class="nav-link" data-bs-toggle="tab" href="#changePassword" role="tab" aria-selected="false" tabindex="-1">
                                                <i class="far fa-user"></i> เปลี่ยนรหัสผ่าน
                                            </a>
                                        </li>
<!--
                                        <li class="nav-item" role="presentation">
                                            <a class="nav-link" data-bs-toggle="tab" href="#experience" role="tab" aria-selected="false" tabindex="-1">
                                                <i class="far fa-envelope"></i> Experience
                                            </a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a class="nav-link" data-bs-toggle="tab" href="#privacy" role="tab" aria-selected="false" tabindex="-1">
                                                <i class="far fa-envelope"></i> Privacy Policy
                                            </a>
                                        </li>
-->
                                    </ul>
                                </div>
                                <div class="card-body p-4">
                                    <div class="tab-content">
                                        <div class="tab-pane active" id="personalDetails" role="tabpanel">
                                            
                                            <form id="formregister" name="formregister" @submit.prevent="savedata" method="post" class="row g-3 needs-validation" novalidate style="font-size: 16px;">
                                                <div class="row">
                                                  <div class="row" style="padding-bottom: 10px;">
                                                       

                                                       <div class="col-md-6">
                                                       <label for="validationCustom03" class="form-label">ประเภทลงทะเบียนเป็นสมาชิก</label>
                                                       <select 
                                                       v-model="membertype"
                                                       :class="{'border border-danger': v$.membertype.$errors.length}" 
                                                       class="form-control">
                                                       <option value="" disabled>เลือกประเภทลงทะเบียนเป็นสมาชิก</option>
                                                       <option 
                                                       :key="membertype.id_typemember" 
                                                       :value="membertype.id_typemember" 
                                                       v-for="membertype in typemembers">

                                                               {{ membertype.typemember_name }}
                                                       </option>

                                                       </select>

                                                      
                                                       <div v-if="v$.membertype.$error" class="text-sm mt-2 text-danger"> 
                                                         {{ v$.membertype.$errors[0].$message }}
                                                       </div>
                                                       <div class="invalid-feedback">
                                                           กรุณาระบุ ประเภทลงทะเบียนเป็นสมาชิก
                                                       </div>
                                                   </div>


                                               </div>


                                                    <div class="row ">

                                                
                                                
                                                   <div class="col-md-6">
                                                        <label for="validationCustom03" class="form-label">ระบุชื่อ</label>
                                                        <input
                                                            :class="{'border border-danger': v$.member.first_name.$errors.length}" 
                                                            v-model="member.first_name"
                                                           
                                                            
                                                            name="first_name" 
                                                            id="first_name" 
                                                            type="text" 
                                                            class="form-control" placeholder="ชื่อ" 
                                                            required>
                                                            <div v-if="v$.member.first_name.$error" class="text-sm mt-2 text-danger"> {{ v$.member.first_name.$errors[0].$message }}</div>
                                                            
                                                            <div class="invalid-feedback">
                                                                กรุณาระบุชื่อ
                                                            </div>

                                                        <div class="invalid-feedback">
                                                            กรุณาระบุชื่อ
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label for="validationCustom03" class="form-label">ระบุนามสกุล</label>
                                                        <input
                                                            :class="{'border border-danger': v$.member.last_name.$errors.length}" 
                                                            v-model="member.last_name" 
                                                            name="last_name" 
                                                            id="last_name" 
                                                            type="text" 
                                                            class="form-control" placeholder="นามสกุล" 
                                                           
                                                            required>
                                                            <div v-if="v$.member.last_name.$error" class="text-sm mt-2 text-danger"> {{ v$.member.last_name.$errors[0].$message }}</div>
                                                        
                                                        <div class="invalid-feedback">
                                                            กรุณาระบุนามสกุล
                                                        </div>
                                                    </div>
                                                </div>
                                                    
                                                   <div class="row" style="padding-top: 25px;">
                                                    <div class="col-md-6">
                                                        <label for="id_card_number" class="form-label">หมายเลขบัตรประชาชน (13 หลัก)</label>
                                                        <input type="text" 
                                                        :class="{'border border-danger': v$.member.id_card_number.$errors.length}" 
                                                        v-model="member.id_card_number"
                                                        @input="checkDigilCardID" 
                                                        class="form-control" 
                                                        id="id_card_number"
                                                        placeholder="หมายเลขบัตรประชาชน (13 หลัก)" 
                                                        required>
                                                        <span v-if="idCardNumberError" style="color: red;">{{ idCardNumberError }}</span>
                                                        <div v-if="v$.member.id_card_number.$error" class="text-sm mt-2 text-danger"> 
                                                              {{ v$.member.id_card_number.$errors[0].$message }}
                                                        </div>
                                                        
                                                    </div>
                                                <div class="col-md-6">
                                                        <label for="validationCustom03" class="form-label">ระบุเบอร์มือถือ (10 หลัก)</label>
                                                        <input disabled
                                                        :class="{'border border-danger': v$.member.mobile_phone_number.$errors.length}" 
                                                        v-model="member.mobile_phone_number" 
                                                        @change="chkphone" 
                                                        type="text" 
                                                        class="form-control" 
                                                        name="mobile_phone_number" 
                                                        id="mobile_phone_number" 
                                                        placeholder="เบอร์มือถือ (10 หลัก)" 
                                                        required>
                                                        <div v-if="v$.member.mobile_phone_number.$error" class="text-sm mt-2 text-danger"> {{ v$.member.mobile_phone_number.$errors[0].$message }}</div>
                                                        <div style="color:red">{{ error_chk_phone }}</div>
                                                        <div class="invalid-feedback">
                                                            กรุณาระบุ เบอร์มือถือ (10 หลัก)
                                                             
                                                        </div>
                                                    </div>
                                                </div>
                                                    
                                                <div class="row" style="padding-top: 25px;">
                                                   <div class="col-md-3">
                                                        <label for="validationCustom03" class="form-label">วันเกิด</label>
                                                        <select  
                                                        :class="{'border border-danger': v$.member.selectedDay.$errors.length}" 
                                                        v-model="member.selectedDay"
                                                        
                                                        class="form-control" 
                                                        style="font-size:16px;"
                                                        >
                                                          <option value="" disabled selected>วันเกิด</option>
                                                         
                                                          <option v-for="day in days" :key="day" :value="day">
                                                              {{ day }}
                                                          </option>
                                                        </select>
                                                        <div v-if="v$.member.selectedDay.$error" class="text-sm mt-2 text-danger"> 
                                                          {{ v$.member.selectedDay.$errors[0].$message }}
                                                        </div>

                                                        <!-- <Datepicker
                                                          placeholder="Select Date"
                                                          :buddhist="true"
                                                          :language="languages.th"
                                                        >
                                                      </Datepicker> -->

                                                        <div class="invalid-feedback">
                                                            กรุณาระบุ วันเกิด
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                      <label for="validationCustom03" class="form-label">เดือนเกิด</label>
                                                      
                                                      <select 
                                                      :class="{'border border-danger': v$.member.selectedMonth.$errors.length}" 
                                                      v-model="member.selectedMonth" 
                                                      class="form-control" style="font-size:16px;">
                                                      <option disabled value="" selected>เลือกเดือนเกิด</option>
                                                      <option v-for="month in months" :key="month.value" :value="month.value">
                                                        {{ month.label }}
                                                      </option>
                                                    </select>
                                                    
                                                    <div v-if="v$.member.selectedMonth.$error" class="text-sm mt-2 text-danger"> 
                                                          {{ v$.member.selectedMonth.$errors[0].$message }}
                                                    </div>


                                                    </div>
                                                    <div class="col-md-3">

                                                      <label for="validationCustom03" class="form-label">ปีเกิด</label>
                                                      
                                                      <select 
                                                      v-model="member.selectedYear" 
                                                      :class="{'border border-danger': v$.member.selectedYear.$errors.length}" 
                                                      class="form-control" style="font-size:16px;">
                                                        <option disabled value="" selected>เลือกปีเกิด</option>
                                                        <option 
                                                        v-for="year in years" 
                                                        :key="year" 
                                                        :value="year">{{ year }}</option>
                                                      </select>

                                                     
                                                      <div v-if="v$.member.selectedYear.$error" class="text-sm mt-2 text-danger"> 
                                                          {{ v$.member.selectedYear.$errors[0].$message }}
                                                    </div>

                                                    </div>

                                                    </div>
                                                    
                                        <div class="row" style="padding-top: 25px;">
                                               <div class="col-md-12">
                                                    <label for="validationCustom03" class="form-label">ที่อยู่</label>
                                                    <input 
                                                        v-model="member.address"
                                                        :class="{'border border-danger': v$.member.address.$errors.length}" 
                                                        type="text" 
                                                        class="form-control" 
                                                        id="address" 
                                                        name="address" 
                                                        placeholder="ที่อยู่" required>
                                                        <div v-if="v$.member.address.$error" class="text-sm mt-2 text-danger"> 
                                                          {{ v$.member.address.$errors[0].$message }}
                                                        </div>

                                                    <div class="invalid-feedback">
                                                        กรุณาระบุ ที่อยู่
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    
                                                </div>
                                                </div>
                                                    
                                          <div class="row" style="padding-top: 25px;">
                                               <div class="col-md-4">
                                                    <label for="validationCustom03" class="form-label">จังหวัด</label>
                                                    <select v-model="province" class="form-control">
                                                         
                                                         <option 
                                                         :key="province.PROVINCE_ID" 
                                                         :value="province.PROVINCE_ID" 
                                                         v-for="province in provinces">
 
                                                                 {{ province.PROVINCE_NAME }}
                                                         </option>
 
                                                         </select>

                                                    <div class="invalid-feedback">
                                                        กรุณาระบุ จังหวัด
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    
                                                    <label for="validationCustom03" class="form-label">อำเภอ</label>
                                                    <select  v-model="district" class="form-control">
                                                        <option :key="district.AMPHUR_ID" :value="district.AMPHUR_ID" v-for="district in districts">
                                                            {{ district.AMPHUR_NAME }}
                                                        </option>
                                                        </select>

                                                    <div class="invalid-feedback">
                                                        กรุณาระบุ อำเภอ
                                                    </div>
                                                    
                                                </div>
                                                    
                                                    <div class="col-md-4">
                                                    
                                                    <label for="validationCustom03" class="form-label">ตำบล</label>
                                                    <select v-model="subdistrict"  class="form-control">
                                                        <option v-bind:key="subdistrict.DISTRICT_ID" :value="subdistrict.DISTRICT_ID" v-for="subdistrict in subdistricts">
                                                            {{ subdistrict.DISTRICT_NAME }}
                                                        </option>
                                                        </select>

                                                    <div class="invalid-feedback">
                                                        กรุณาระบุ ตำบล
                                                    </div>
                                                    
                                                </div>
                                        </div>
                                                 
                                                    
                                        <div class="row" style="padding-top: 25px;">
                                                   
                                                        
                                                   <div class="col-md-6">
                                                       <div class="mb-3">
                                                               <label class="form-label" for="gen-info-email-input">อีเมล (ถ้ามี)</label>
                                                               <input
                                                               v-model="member.email_member"
                                                               type="text" 
                                                               class="form-control" id="gen-info-email-input" placeholder="อีเมล" >
                                                               
                                                               
                                                          
                                                           </div>
                                               </div>

                                               <div class="col-md-6">
                                                       <div class="mb-3">
                                                               <label class="form-label" for="gen-info-email-input">Line ID (ถ้ามี)</label>
                                                               <input
                                                               v-model="member.line_id"
                                                               type="text" 
                                                               class="form-control" id="gen-info-email-input" placeholder="Line ID" >
                                                               
                                                               
                                                           
                                                           </div>
                                               </div>
                                              
                                               </div>
                                                    
                                                  
                                                    
                                                  
                                                  
                                                    <div class="col-lg-12" style="padding-top: 10px;">
                                                        <div class="hstack gap-2 justify-content-end">
                                                          <input v-model="member.member_id" type="hidden" class="form-control" id="member_id" name="member_id" placeholder="Line ID" required>
                                                            <button id="btnSend" type="submit" class="btn btn-primary">Updates</button>
                                                            
                                                        </div>
                                                    </div>
                                                    <!--end col-->
                                                </div>
                                                <!--end row-->
                                            </form>
                                        </div>
                                        <!--end tab-pane-->
                                        <div class="tab-pane" id="changePassword" role="tabpanel">
                                            
                                                <div class="row g-2">
                                                   
                                                    <div class="col-lg-4">
                                                        <div>
                                                            <label for="newpasswordInput" class="form-label">รหัสผ่านใหม่*</label>
                                                           
                                                            <input 
                                                            v-model="newpass.password" 
                                                            
                                                            name="password" 
                                                            id="password" 
                                                            type="password" 
                                                            class="form-control pe-5 password-input1" 
                                                            placeholder="รหัสผ่าน">
                                                            <button class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon">
                                                             
                                                            </button>
                                                            
                                                            <div  class="invalid-feedback" id="feedback-1" v-if="errors[0]">
                                                                {{ errors[0].message }}
                                                            </div>  
                                                        </div>
                                                    </div>
                                                    <!--end col-->
                                                   
                                                    <!--end col-->

                                                    <div class="col-lg-12">
                                                        <div class="text-end">
                                                            <button @click="savedatapassword" class="btn btn-success">ยืนยันเปลี่ยนรหัสผ่าน</button>
                                                        </div>
                                                    </div>
                                                  
                                                </div>
                                                <!--end row-->
                                           
                                            
                                           
                                          
                                          
                                        </div>
                                        <!--end tab-pane-->
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--end col-->
                    </div>
                    <!-- <div v-if="responseprofile">
                    <h1 v-if="responseprofile[0].member_id==13">Member ID: {{ responseprofile[0].member_id }}</h1>
                    <p>Username: {{ responseprofile[0].first_name }}</p>
                    <p>Email: {{ responseprofile[0].last_name }}</p>
                   
                  </div>   -->
</template>


<script>
  import axios from "axios";
    // Import Vuelidate
    import useVuelidate from '@vuelidate/core';
    //import { required, email, minLength, sameAs, helpers } from '@vuelidate/validators';
    import { required, helpers } from '@vuelidate/validators';
    //import Datepicker from 'vuejs-datepicker-thai';
   // import http from '@service/BackendService';

   import http from '@/services/BackendService'

export default {
  data() {
    return {
      v$: useVuelidate(),
        member_id: '',
        provinces: [],
       // responseprofile: [],
        districts: [],
        subdistricts: [],
        password: '',
        confirm_password:'',
        province: '',
        district: '',
        subdistrict: '',
        membertype: '',
        errors: [],
        nameError: false,
        first_name: '',
        last_name: '',
        imagePath: require('@/assets/loglabai.png'),
        showPassword1: false,
        showPassword2: false,
        mobile_phone_number: '',
        error_chk_phone:'',
        url: process.env.VUE_APP_URL,
        urlapi: process.env.VUE_APP_API_URL,
        responseprofile:'',
        preview: null,
        image: null,
        preview_list: [],
        image_list: [],
        selectedFile: null,
        id_card_number: null,
        idCardNumberError: null,
        days: Array.from({ length: 31 }, (_, index) => index + 1),
        selectedDay: null,
        selectedMonth: null,
        selectedYear: null,
        email_member:'',
        line_id:'',
        getprovince:null,
        


        months: [
        { value: 1, label: 'มกราคม' },
        { value: 2, label: 'กุมภาพันธ์' },
        { value: 3, label: 'มีนาคม' },
        { value: 4, label: 'เมษายน' },
        { value: 5, label: 'พฤษภาคม' },
        { value: 6, label: 'มิถุนายน' },
        { value: 7, label: 'กรกฎาคม' },
        { value: 8, label: 'สิงหาคม' },
        { value: 9, label: 'กันยายน' },
        { value: 10, label: 'ตุลาคม' },
        { value: 11, label: 'พฤศจิกายน' },
        { value: 12, label: 'ธันวาคม' },
      ],
        
        years:[],
      member: {
            member_id: '',
            first_name: '',
            last_name: '',
            province: '',
            district: '',
            subdistrict: '',
            mobile_phone_number: '',
            id_card_number: null,
            selectedDay: null,
            selectedMonth: null,
            selectedYear: null,
            membertype: '',
          
           
          },
        newpass: {
            password: '',
            confirm_password:'',
           
          
           
          }
     
    };
  },

  validations() {
      return {
        member: {
            first_name: {
            required:helpers.withMessage('กรุณาระบุชื่อ', required), 
          },
          last_name: {
            required:helpers.withMessage('กรุณาระบุนามสกุล', required), 
          },
          mobile_phone_number: {
            required:helpers.withMessage('กรุณาระบุเบอร์โทรศัพท์', required), 
          },
          id_card_number: {
            required:helpers.withMessage('กรุณาระบุเลขบัตรประจำตัว 13 หลัก', required), 
          },
          selectedDay: {
            required:helpers.withMessage('กรุณาระบุวันเกิด', required), 
          },
          selectedMonth: {
            required:helpers.withMessage('กรุณาระบุวันเกิด', required), 
          },
          selectedYear: {
            required:helpers.withMessage('กรุณาระบุวันเกิด', required), 
          },
          address: {
            required:helpers.withMessage('กรุณาระบุที่อยู่', required), 
          },
         



        },
        membertype: {
            required:helpers.withMessage('กรุณาระบุประเภทการลงทะเบียน', required), 
          },

          
        }
    },
  
  mounted() {

    
    // ดึงข้อมูลผู้ใช้จาก localStorage
    const userData = localStorage.getItem('user');
    const getdata = JSON.parse(userData);
   
  //  const member_id = getdata.user.member_id;
  //  console.log(member_id);
    if (userData) {
      // แปลงข้อมูล JSON กลับเป็น object
      this.user = JSON.parse(userData);
      this.member.member_id = getdata.user.member_id;
      this.token = "Bearer "+getdata.user.member_id+"|"+getdata['token'];
     

      
      
      // this.member.first_name = getdata.user.first_name;
      // this.member.last_name = getdata.user.last_name;
      
      
       //console.log(this.token);
    }else{
      this.$router.push('login')
    }
   

    // let responsepro = http.get('profiledata/'+this.member);
    //  this.responseprofile = responsepro.data;

     // เรียก API ข้อมูล Profile
    //  axios.post(process.env.VUE_APP_API_URL + 'profiledata', {
    //         member_id: getdata.user.member_id
    //   })
    //     .then((responsepro) => {
          
    //         this.responseprofile = responsepro.data;
    //         this.member.first_name = this.responseprofile[0].first_name;
    //         console.log('member.first_name:',  this.member.first_name);
            
    //     })
    //     .catch((error) => {
    //         console.error('Error during POST request:', error);
    //     });


    http.post(process.env.VUE_APP_API_URL + 'profiledata', {
            member_id: getdata.user.member_id
      })
        .then((responsepro) => {
          
            this.responseprofile = responsepro.data;
            this.member.first_name = this.responseprofile[0].first_name;
            this.member.last_name = this.responseprofile[0].last_name;
            this.member.id_card_number = this.responseprofile[0].id_card_number;
            this.member.mobile_phone_number = this.responseprofile[0].mobile_phone_number;
            this.member.address = this.responseprofile[0].address;
            this.getprovince = this.responseprofile[0].province_id;
            this.getdistrict = this.responseprofile[0].amphur_id;
            this.getsubdistrict = this.responseprofile[0].district_id;
            this.member.email_member = this.responseprofile[0].email_member;
            this.member.line_id = this.responseprofile[0].line_id;
        
            this.member.line_id = this.responseprofile[0].line_id;
            
           


            this.preview = process.env.VUE_APP_PIC_PROFILE_URL+this.responseprofile[0].avatar;
            this.avatar = this.responseprofile[0].avatar;
            //console.log('member.first_name:',  this.member.first_name);

            const birthdate = this.responseprofile[0].birthdate.split("-");

          
            this.member.selectedDay =  parseInt(birthdate[2], 10);
           
            this.member.selectedMonth = parseInt(birthdate[1], 10);
            this.member.selectedYear = birthdate[0];

            this.membertype = this.responseprofile[0].id_typemember;

            // เรียก API เพื่อดึงข้อมูลจังหวัด
         axios.get(process.env.VUE_APP_API_URL+"slectpprovince")
            .then((response) => {
                
                
                this.provinces = response.data;
                if (this.provinces.length > 0) {
                        this.province = this.getprovince;
                        this.district = this.getdistrict;
                        this.subdistrict = this.getsubdistrict;
                    }
            });

            axios.get(process.env.VUE_APP_API_URL+"slectaumph/" + this.getprovince).then((response) => {
                this.districts = response.data;
            });

            axios.get(process.env.VUE_APP_API_URL+"slectdist/" + this.getdistrict).then((response) => {
                this.subdistricts = response.data;
            });
            
        })
        .catch((error) => {
            console.error('Error during POST request:', error);
        });

      

         


        axios.get(process.env.VUE_APP_API_URL+"selectedYear").then((response) => {
          this.years = response.data;
        });
       

        axios.get(process.env.VUE_APP_API_URL+"slectmembertype").then((response) => {
          this.typemembers = response.data;

          
        });

  },
  

  watch: {
        province() {
          // เมื่อเลือกจังหวัดแล้ว ให้ดึงข้อมูลอำเภอมาแสดง
          axios.get(process.env.VUE_APP_API_URL+"slectaumph/" + this.province).then((response) => {
            this.districts = response.data;
          });
        },
        district() {
          // เมื่อเลือกอำเภอแล้ว ให้ดึงข้อมูลตำบลมาแสดง
          axios.get(process.env.VUE_APP_API_URL+"slectdist/" + this.district).then((response) => {
            this.subdistricts = response.data;
          });
        },
      },

      methods: {
        //https://codepen.io/chenuin/pen/MdvyjG
        previewImage(event) {
            var input = event.target;
            this.selectedFile = event.target.files[0];

            
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.preview = e.target.result;
                };
                    this.image = input.files[0];
                reader.readAsDataURL(input.files[0]);


                 // ดึงข้อมูลไฟล์จากอิเล็มเมนต์ input
                const fileInput = this.$refs.fileInput;
                const file = fileInput.files[0];

                // ตรวจสอบว่ามีไฟล์หรือไม่
                if (!file) {
                    console.error('ไม่พบไฟล์ที่จะอัปโหลด');
                    return;
                }

                const fileName = file.name;
                // Check if the file has a .png or .jpg extension
                if (/\.(png|jpg)$/i.test(fileName)) {
                    
                        // สร้าง FormData เพื่อเก็บไฟล์
                        const formData = new FormData();

                                            
                              formData.append('fileimgprofile', file);
                              formData.append('member_id', this.member.member_id);
                              formData.append('avatar1', this.avatar);


                              try {
                                  // ทำการ POST ข้อมูลไปยังเซิร์ฟเวอร์
                                  //const response = await axios.post('/api/upload', formData, {

                                  http.post(process.env.VUE_APP_API_URL + 'uploadprofile', formData, {
                                      headers: {
                                      'Content-Type': 'multipart/form-data',
                                      }
                                  })
                                      .then((response) => {
                                      
                                        // ตรวจสอบการตอบกลับจากเซิร์ฟเวอร์
                                          console.log('ไฟล์ถูกอัปโหลดเรียบร้อย', response.data);
                                          location.reload();
                                      })
                                      .catch((error) => {
                                          console.error('Error during POST request:', error);
                                      });


                                  
                              } catch (error) {
                                  console.error('เกิดข้อผิดพลาดในการอัปโหลดไฟล์', error);
                              }



                } else {
                    // Invalid file extension
                   // console.error('ไฟล์ที่เลือกต้องเป็น .png หรือ .jpg เท่านั้น');

                   document.getElementById('img_error_message').innerText = 'ไฟล์ที่เลือกต้องเป็น .png หรือ .jpg เท่านั้น';
                    return;
                }

               
                



                
            }
        },
       

        togglePasswordVisibility(field) {
          //alert(this.showPassword1);

          this.showPassword1 = !this.showPassword1;
          this.showPassword2 = !this.showPassword2;
          if (this.$el && this.$el instanceof HTMLElement) {
            const passwordInput = this.$el.querySelector('.password-input'+field);
            if (this.showPassword1) {
              passwordInput.type = 'text';
            } else {
              passwordInput.type = 'password';
            }

            if (this.showPassword2) {
              passwordInput.type = 'text';
            } else {
              passwordInput.type = 'password';
            }
          }

          
        },

        checkDigilCardID() {
            // ใส่รูปแบบ Regex ที่ต้องการตรวจสอบ
            const idCardRegex = /^\d{13}$/;

            if (this.member.id_card_number.match(idCardRegex)) {
              this.idCardNumberError = ''; // ไม่มีข้อผิดพลาด
            } else {
              this.idCardNumberError = 'หมายเลขบัตรประชาชนไม่ถูกต้อง';
            }

        },
    async savedata() {


       
  
      try {
        this.v$.$validate() // เช็คตัว input ทุกช่อง
        this.errors = [];
		//var len = this.member.password.length;
    //alert(this.v$.$error);
        if(!this.v$.$error) {
        
        


                /*if (len < 5 || len > 20) {
					this.nameError = true;
					this.errors.push({
						message: 'Name must be between 5 to 20 characters long.'
                       
					});
                    return false;
				} else {
					document.getElementById('password').className = "form-control is-valid";
					this.errors.push({
						message: 'Validated.'
					});
					document.getElementById('feedback-1').className = "valid-feedback";
				}*/

               
        this.member.province = this.province; // อัปเดตค่าจังหวัดจาก dropdown
        this.member.district = this.district; // อัปเดตค่าอำเภอจาก dropdown
        this.member.subdistrict = this.subdistrict; // อัปเดตค่าตำบลจาก dropdown

        this.member.membertype = this.membertype;

        const response = await fetch(process.env.VUE_APP_API_URL+'updatemember', {
        //const response = http.post(process.env.VUE_APP_API_URL+'updatemember', {
          method: 'POST',
          headers: {
            "Content-type": "application/json",
            "Accept": "application/json",
            "Authorization": `${this.token}`
          },
          body: JSON.stringify(this.member)
        });

        if (response.ok) {
          // ทำอะไรก็ตามที่คุณต้องการหลังจากลงทะเบียนสำเร็จ
          //console.log('ลงทะเบียนสำเร็จ!');
            //alert('ลงทะเบียนสำเร็จ!')
          // this.$swal('Hello Vue world!!!');

          // ลงทะเบียนสำเร็จ
          this.$swal({
            title: 'สำเร็จ!',
            text: 'แก้ไขข้อมูลสำเร็จ',
            icon: 'success',
            confirmButtonText: 'ตกลง'
            //this.$router.push('/land_issue');
          });

        } else {
          // กรณีเกิดข้อผิดพลาด
         // console.error('เกิดข้อผิดพลาดในการลงทะเบียน');

         
         this.$swal({
            title: 'เกิดข้อผิดพลาด',
            text: 'เกิดข้อผิดพลาดในการลงทะเบียน',
            icon: 'error',
            confirmButtonText: 'ตกลง'
            // สามารถเพิ่มตัวเลือกอื่น ๆ ตามต้องการ
          });

        }
        }// endif

        
        
      } catch (error) {
        //console.error('มีข้อผิดพลาดในการเชื่อมต่อกับ API:', error);
        this.$swal({
          title: 'เกิดข้อผิดพลาด',
          text: 'มีข้อผิดพลาดในการเชื่อมต่อกับ API: ' + error,
          icon: 'error',
          confirmButtonText: 'ตกลง'
          // สามารถเพิ่มตัวเลือกอื่น ๆ ตามต้องการ
        });

      }
    },/// end savedata


async savedatapassword() {


       
  
try {
  this.v$.$validate() // เช็คตัว input ทุกช่อง
  this.errors = [];
  //var len = this.member.password.length;
//alert(this.v$.$error);
  if(!this.v$.$error) {
  
    this.newpass.member_id = this.member.member_id;
  const response = await fetch(process.env.VUE_APP_API_URL+'updatememberpassword', {
    method: 'POST',
    headers: {
      "Content-type": "application/json",
      "Accept": "application/json",
      "Authorization": `${this.token}`
    },
    body: JSON.stringify(this.newpass)
  });

  if (response.ok) {
   
    // ลงทะเบียนสำเร็จ
    this.$swal({
      title: 'สำเร็จ!',
      text: 'เปลี่ยนรหัสผ่านสำเร็จ',
      icon: 'success',
      confirmButtonText: 'ตกลง',
      
      //this.$router.push('/land_issue');
      
    });
    this.newpass.password = "";

  } else {
   
   this.$swal({
      title: 'เกิดข้อผิดพลาด',
      text: 'เกิดข้อผิดพลาดในการลงทะเบียน',
      icon: 'error',
      confirmButtonText: 'ตกลง'
      // สามารถเพิ่มตัวเลือกอื่น ๆ ตามต้องการ
    });

  }
  }// endif

  
  
} catch (error) {
  //console.error('มีข้อผิดพลาดในการเชื่อมต่อกับ API:', error);
  this.$swal({
    title: 'เกิดข้อผิดพลาด',
    text: 'มีข้อผิดพลาดในการเชื่อมต่อกับ API: ' + error,
    icon: 'error',
    confirmButtonText: 'ตกลง'
    // สามารถเพิ่มตัวเลือกอื่น ๆ ตามต้องการ
  });

}
},/// end savedata

    

        

        
      },
  
};
</script>
