<template>
 <div style="padding-left: 30px;">
    <div class="col-xxl-6">
                                    <div class="card">
                                        <div class="row g-0">
                                            <div class="col-md-4">
                                                <img class="rounded-start img-fluid h-100 object-cover" :src="preview" alt="Card image">
                                            </div>
                                            <div class="col-md-8">
                                                <div class="card-header">
                                                    <h5 class="mt-0">{{ this.first_name }} {{ this.last_name }}  </h5>
                                                </div>
                                                <div class="card-body">
                                                    <p class="card-text mb-2">หมายเลขสมาชิก : {{ formatNumber(this.member_id) }}</p>
                                                    <p class="card-text mb-2">เป็นสมาชิกเมื่อ : {{ this.created_at }}</p>
                                                    <!-- <qrcode-vue3 
                                                        value="ปปปปป"
                                                        :width="70"
                                                        :height="70"
                                                        color="#3498db" 
                                                        >
                                                    </qrcode-vue3> -->
                                                    
                                                    <p class="card-text" style="padding-top:  10px;">
                                                        <img v-bind:src="imagePath" style="height: 40px; width: 30px;" />
                                                        <small class="text-muted"> สถาบันบริหารจัดการธนาคารที่ดิน (องค์การมหาชน)</small>
                                                        
                                                    </p>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div><!-- end card -->
                                </div>

  <!-- <qrcode-vue3 
  :value="getdataqrcode(member_id)"
  :width="70"
  :height="70"
  

> 
</qrcode-vue3>-->



<!-- https://gerardreches.github.io/vue-qrcode-component/ -->
<!-- <input type="text" v-model="message">
<qr-code 
    :text="message"
    size="200"
    
    error-level="L">
</qr-code> -->

<!-- <qrcode-vue3
          :width="200"
          :height="200"
          value="https://scholtz.sk"
          :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
          :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0 }"
          :dotsOptions="{
            type: 'dots',
            color: '#26249a',
            gradient: {
              type: 'linear',
              rotation: 0,
              colorStops: [
                { offset: 0, color: '#26249a' },
                { offset: 1, color: '#26249a' },
              ],
            },
          }"
          :backgroundOptions="{ color: '#ffffff' }"
          :cornersSquareOptions="{ type: 'dot', color: '#000000' }"
          :cornersDotOptions="{ type: undefined, color: '#000000' }"
          fileExt="png"
          :download="true"
          myclass="my-qur"
          imgclass="img-qr"
          downloadButton="my-button"
          :downloadOptions="{ name: 'vqr', extension: 'png' }"
        /> -->

    
</div>
        


    
</template>

<script>

import http from '@/services/BackendService';
//import QrcodeVue3 from 'qrcode-vue3';
import { ref } from 'vue';

export default {
    components: {
       // QrcodeVue3,
  },
    data() {
        
            const message = ref(200);
            return {
                preview : null,
                member_id: '',
                first_name: '',
                last_name: '',
                created_at: '',
                imagePath: require('@/assets/loglabai.png'),
                dataqrcode:this.member_id,
                message,
                
                
            }
        
    },
    mounted() {

    // ดึงข้อมูลผู้ใช้จาก localStorage
    const userData = localStorage.getItem('user');
    const getdata = JSON.parse(userData);

    if (userData) {
      // แปลงข้อมูล JSON กลับเป็น object
      this.user = JSON.parse(userData);
      this.member_id = getdata.user.member_id;
      this.token = "Bearer "+getdata.user.member_id+"|"+getdata['token'];
     
    }else{
      this.$router.push('login')
    }


    http.post(process.env.VUE_APP_API_URL + 'profiledata', {
            member_id: getdata.user.member_id
      })
        .then((responsepro) => {
          
            this.responseprofile = responsepro.data;
            this.getmember_id = this.responseprofile[0].member_id;
            this.first_name = this.responseprofile[0].first_name;
            this.last_name = this.responseprofile[0].last_name;
            this.created_at = this.responseprofile[0].created_at;
            
            this.preview = process.env.VUE_APP_PIC_PROFILE_URL+this.responseprofile[0].avatar;
            this.avatar = this.responseprofile[0].avatar;
            
            console.log('member.first_name:',  this.first_name);
            
            
        })
        .catch((error) => {
            console.error('Error during POST request:', error);
        });

        

    },

    methods: {
    formatNumber(number) {
       
      return String(number).padStart(8, '0');
    },
    getdataqrcode(member_id) {
      // เรียกฟังก์ชันเพื่อรับค่า member_id จากฐานข้อมูล
      
      console.log(member_id);
     // console.log(this.getmember_id);
      
      //const dataqrcode = "https://www.ggo.com/${member_id}";
      
      const return_dataqrcode = `https://www.ggo.com/test?member_id=${member_id}`;
      console.log(return_dataqrcode);
      
      return return_dataqrcode;
    },
  },
 

}


</script>