import axios from "axios";

const authService = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true, // ฝั่ง laravel หรือ backend ต้องไปที่ config-->cors.php 'supports_credentials' => true,
  //method: 'POST',
  headers: {
    "Content-type": "application/json",
    "Accept": "application/json"
  },
});

export default authService;