<template>

	

       
        <!-- auth-page wrapper -->
    <!--    <div class="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">-->
         <div style="background-color:#fff; " id="#top" class="auth-page-wrapper justify-content-center align-items-center min-vh-100" >
    <!--        <div class="bg-overlay"></div>-->
            <!-- auth-page content -->
    <!--        <div class="auth-page-content overflow-hidden pt-lg-5">-->


           
             <div class="auth-page-content overflow-hidden pt-lg-0" >
                <div class="container" style="margin-top:200px;">
                    <div class="row">
                        <div class="col-lg-12">

                          
                            <div class="card overflow-hidden">
                                <div class="row g-0">
                                    
                                    <!-- end col -->
    
                                    <div class="col-lg-12">
                                        <div class="p-lg-5 p-4">
                                            <div style="text-align: center;">
                                                <div style="text-align: center;">
                                            <!-- <img src="public/assets/images/logolabai3.png" style="height: 150px; width: 150px;"> -->
                                            <!-- <img v-bind:src="imagePath" style="height: 150px; width: 180px;" /> -->
                                            
                                            </div>
                                            <div style="text-align: center;">
                                                
                                                <h2 style="padding-bottom: 10px; padding-top: 10px;" class="text-primary">ลงทะเบียนสมัครสมาชิกสถาบันบริหารจัดการธนาคารที่ดิน (องค์การมหาชน)</h2>
                                               
                                                
                                            </div>
                                                
                                            </div>
    
                                            <div class="mt-4">
                                                <form id="formregister" name="formregister" @submit.prevent="savedata" method="post" class="row g-3 needs-validation" novalidate style="font-size: 16px;">
                                               
                                                
                                                  <div class="row" style="padding-bottom: 10px;">
                                                       

                                                        <div class="col-md-6">
                                                        <label for="validationCustom03" class="form-label">ประเภทลงทะเบียนเป็นสมาชิก</label>
                                                        <select 
                                                        v-model="membertype"
                                                        :class="{'border border-danger': v$.membertype.$errors.length}" 
                                                        class="form-control">
                                                        <option value="" disabled>เลือกประเภทลงทะเบียนเป็นสมาชิก</option>
                                                        <option 
                                                        :key="membertype.id_typemember" 
                                                        :value="membertype.id_typemember" 
                                                        v-for="membertype in typemembers">

                                                                {{ membertype.typemember_name }}
                                                        </option>

                                                        </select>

                                                       
                                                        <div v-if="v$.membertype.$error" class="text-sm mt-2 text-danger"> 
                                                          {{ v$.membertype.$errors[0].$message }}
                                                        </div>
                                                        <div class="invalid-feedback">
                                                            กรุณาระบุ ประเภทลงทะเบียนเป็นสมาชิก
                                                        </div>
                                                    </div>


                                                </div>
                                                
                                                    
                                                <div class="row ">
                                                       <div class="col-md-6">
                                                            <label for="validationCustom03" class="form-label">ระบุชื่อ</label>
                                                            <input
                                                            :class="{'border border-danger': v$.member.first_name.$errors.length}" 
                                                            v-model="member.first_name" 
                                                            name="first_name" 
                                                            id="first_name" 
                                                            type="text" 
                                                            class="form-control" placeholder="ชื่อ" required>
                                                            <div v-if="v$.member.first_name.$error" class="text-sm mt-2 text-danger"> 
                                                              {{ v$.member.first_name.$errors[0].$message }}
                                                              </div>
                                                            
                                                            <div class="invalid-feedback">
                                                                กรุณาระบุชื่อ
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label for="validationCustom03" class="form-label">ระบุนามสกุล</label>
                                                            <input 
                                                            
                                                            :class="{'border border-danger': v$.member.last_name.$errors.length}" 
                                                            v-model="member.last_name" 
                                                            name="last_name" 
                                                            id="last_name" 
                                                            type="text" 
                                                            class="form-control" placeholder="นามสกุล" required>

                                                            <div v-if="v$.member.last_name.$error" class="text-sm mt-2 text-danger"> 
                                                              {{ v$.member.last_name.$errors[0].$message }}
                                                              </div>
    
                                                            <div class="invalid-feedback">
                                                                กรุณาระบุนามสกุล
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="row" style="padding-top: 25px;">
                                                   <div class="col-md-6">
                                                        <label for="id_card_number" class="form-label">หมายเลขบัตรประชาชน (13 หลัก)</label>
                                                        <input type="text"
                                                        :class="{'border border-danger': v$.member.id_card_number.$errors.length}" 
                                                        v-model="member.id_card_number"
                                                        @change="chkcard_number" 
                                                        @input="checkDigilCardID" 
                                                        class="form-control" 
                                                        id="id_card_number" 
                                                        placeholder="หมายเลขบัตรประชาชน (13 หลัก)" 
                                                        required>
                                                        <span v-if="idCardNumberError" style="color: red;">{{ idCardNumberError }}</span>
                                                        <div v-if="v$.member.id_card_number.$error" class="text-sm mt-2 text-danger"> 
                                                              {{ v$.member.id_card_number.$errors[0].$message }}
                                                              </div>
                                                              <div style="color:red">{{ error_chk_cardnumber }}</div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label for="validationCustom03" class="form-label">ระบุเบอร์มือถือ (10 หลัก)</label>
                                                        <input
                                                        :class="{'border border-danger': v$.member.mobile_phone_number.$errors.length}" 
                                                        maxlength="10"
                                                        v-model="member.mobile_phone_number" 
                                                        @change="chkphone" 
                                                        type="text" 
                                                        class="form-control" 
                                                        name="mobile_phone_number" 
                                                        id="mobile_phone_number" 
                                                        placeholder="เบอร์มือถือ (10 หลัก)" 
                                                        required>
                                                        <div v-if="v$.member.mobile_phone_number.$error" class="text-sm mt-2 text-danger"> 
                                                          {{ v$.member.mobile_phone_number.$errors[0].$message }}
                                                          </div>
                                                        <div style="color:red">{{ error_chk_phone }}</div>
                                                        <div class="invalid-feedback">
                                                            กรุณาระบุ เบอร์มือถือ (10 หลัก)
                                                             
                                                        </div>
                                                    </div>
                                                    </div>
                                                    
                                                  <div class="row" style="padding-top: 25px;">
                                                   <div class="col-md-3">
                                                        <label for="" class="form-label">วันเกิด</label>
                                                        <select  
                                                        v-model="member.selectedDay"
                                                        :class="{'border border-danger': v$.member.selectedDay.$errors.length}" 
                                                        class="form-control" 
                                                       
                                                        >
                                                          <option value="" disabled>เลือกวันเกิด</option>
                                                          <option 
                                                          :key="day" 
                                                          :value="day"
                                                          v-for="day in days" >
                                                              {{ day }}
                                                          </option>
                                                        </select>
                                                        <div v-if="v$.member.selectedDay.$error" class="text-sm mt-2 text-danger"> 
                                                          {{ v$.member.selectedDay.$errors[0].$message }}
                                                        </div>
                                                   <!-- <Datepicker
                                                          placeholder="Select Date"
                                                          :buddhist="true"
                                                          :language="languages.th"
                                                        >
                                                      </Datepicker> -->

                                                        <div class="invalid-feedback">
                                                            กรุณาระบุ วันเกิด
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                      <label for="validationCustom03" class="form-label">เดือนเกิด</label>
                                                      
                                                      <select 
                                                      :class="{'border border-danger': v$.member.selectedMonth.$errors.length}" 
                                                      v-model="member.selectedMonth" 
                                                      class="form-control" >
                                                      <option disabled value="" selected>เลือกเดือนเกิด</option>
                                                      <option v-for="month in months" :key="month.value" :value="month.value">
                                                        {{ month.label }}
                                                      </option>
                                                    </select>
                                                    
                                                    <div v-if="v$.member.selectedMonth.$error" class="text-sm mt-2 text-danger"> 
                                                          {{ v$.member.selectedMonth.$errors[0].$message }}
                                                    </div>


                                                    </div>
                                                    <div class="col-md-3">

                                                      <label for="validationCustom03" class="form-label">ปีเกิด</label>
                                                      
                                                      <select 
                                                      v-model="member.selectedYear" 
                                                      :class="{'border border-danger': v$.member.selectedYear.$errors.length}" 
                                                      class="form-control" >
                                                        <option disabled value="" selected>เลือกปีเกิด</option>
                                                        <option 
                                                        v-for="year in years" 
                                                        :key="year" 
                                                        :value="year">{{ year }}</option>
                                                      </select>

                                                     
                                                      <div v-if="v$.member.selectedYear.$error" class="text-sm mt-2 text-danger"> 
                                                          {{ v$.member.selectedYear.$errors[0].$message }}
                                                    </div>

                                                    </div>

                                                    </div>
                                                    
                                                     <div class="row" style="padding-top: 25px;">
                                                   <div class="col-md-12">
                                                        <label for="validationCustom03" class="form-label">ที่อยู่</label>
                                                        <input 
                                                        v-model="member.address"
                                                        :class="{'border border-danger': v$.member.address.$errors.length}" 
                                                        type="text" 
                                                        class="form-control" 
                                                        id="address" 
                                                        name="address" 
                                                        placeholder="ที่อยู่" required>
                                                        <div v-if="v$.member.address.$error" class="text-sm mt-2 text-danger"> 
                                                          {{ v$.member.address.$errors[0].$message }}
                                                        </div>
                                                        <div class="invalid-feedback">
                                                            กรุณาระบุ ที่อยู่
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        
                                                    </div>
                                                    </div>
                                                    
                                                    <div class="row" style="padding-top: 25px;">
                                                   <div class="col-md-4">
                                                        <label for="validationCustom03" class="form-label">จังหวัด</label>
                                                        <select 
                                                        v-model="province"
                                                        :class="{'border border-danger': v$.province.$errors.length}" 
                                                        class="form-control">
                                                        <option disabled value="" selected>เลือกจังหวัด</option>
                                                        <option 
                                                        :key="province.PROVINCE_ID" 
                                                        :value="province.PROVINCE_ID" 
                                                        v-for="province in provinces">

                                                                {{ province.PROVINCE_NAME }}
                                                        </option>

                                                        </select>

                                                       
                                                        <div v-if="v$.province.$error" class="text-sm mt-2 text-danger"> 
                                                          {{ v$.province.$errors[0].$message }}
                                                        </div>
                                                        <div class="invalid-feedback">
                                                            กรุณาระบุ จังหวัด
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        
                                                        <label for="validationCustom03" class="form-label">อำเภอ</label>
                                                        <select  
                                                        v-model="district" 
                                                        :class="{'border border-danger': v$.district.$errors.length}" 
                                                        class="form-control">
                                                        <option disabled value="" selected>เลือกอำเภอ</option>
                                                        <option :key="district.AMPHUR_ID" :value="district.AMPHUR_ID" v-for="district in districts">
                                                            {{ district.AMPHUR_NAME }}
                                                        </option>
                                                        </select>
                                                        
                                                        <div v-if="v$.district.$error" class="text-sm mt-2 text-danger"> 
                                                          {{ v$.district.$errors[0].$message }}
                                                        </div>
                                                        <div class="invalid-feedback">
                                                            กรุณาระบุ อำเภอ
                                                        </div>
                                                        
                                                    </div>
                                                        
                                                        <div class="col-md-4">
                                                        
                                                        <label for="validationCustom03" class="form-label">ตำบล</label>
                                                        <select 
                                                        v-model="subdistrict" 
                                                        :class="{'border border-danger': v$.subdistrict.$errors.length}"  
                                                        class="form-control">
                                                        <option disabled value="" selected>เลือกตำบล</option>
                                                        <option v-bind:key="subdistrict.DISTRICT_ID" :value="subdistrict.DISTRICT_ID" v-for="subdistrict in subdistricts">
                                                            {{ subdistrict.DISTRICT_NAME }}
                                                        </option>
                                                        </select>
                                                        
                                                        <div v-if="v$.subdistrict.$error" class="text-sm mt-2 text-danger"> 
                                                          {{ v$.subdistrict.$errors[0].$message }}
                                                        </div>
                                                        <div class="invalid-feedback">
                                                            กรุณาระบุ ตำบล
                                                        </div>
                                                        
                                                    </div>
                                                    </div>
                                                    
                                                    
                                                   <div class="row" style="padding-top: 25px;">
                                                   <div class="col-md-6">
                                                        <label for="validationCustom03" class="form-label">กำหนดรหัสผ่าน</label>
                                                        <div class="position-relative auth-pass-inputgroup mb-3">
                                                            <input 
                                                            v-model="member.password" 
                                                            :class="{'border-red-600': v$.member.password.$errors.length}" 
                                                            name="password" 
                                                            id="password" 
                                                            type="password" 
                                                            class="form-control pe-5 password-input1" 
                                                            placeholder="รหัสผ่าน">
                                                            <button @click="togglePasswordVisibility(1)" class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon">
                                                              <!-- <i class="ri-eye-fill align-middle"></i> -->
                                                              <i v-if="showPassword1" class="ri-eye-fill align-middle"></i>
                                                              <i v-else class="ri-eye-off-fill align-middle"></i>
                                                            </button>
                                                            <div style="color: red" v-if="v$.member.password.$error" class="text-sm mt-2 text-red-700"> {{ v$.member.password.$errors[0].$message }}</div>
                                                        </div>
                                                        <div  class="invalid-feedback" id="feedback-1" v-if="errors[0]">
                                                            {{ errors[0].message }}
                                                        </div>
                                                        <div class="invalid-feedback">
                                                            กรุณาระบุรหัสผ่าน
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label for="confirm_password" class="form-label">กำหนดรหัสผ่านอีกครั้ง</label>
                                                        <div class="position-relative auth-pass-inputgroup mb-3">
                                                            <input
                                                            v-model="member.confirm_password"
                                                            :class="{'border-red-600': v$.member.confirm_password.$errors.length}" 
                                                            type="password" 
                                                            class="form-control pe-5 password-input2" 
                                                            placeholder="รหัสผ่าน" 
                                                            id="confirm_password"
                                                            name="confirm_password"
                                                            >
                                                            <button @click="togglePasswordVisibility(2)" 
                                                            class="btn btn-link position-absolute 
                                                            end-0 top-0 text-decoration-none text-muted password-addon" 
                                                            type="button" id="password-addon">
                                                            <i v-if="showPassword2" class="ri-eye-fill align-middle"></i>
                                                            <i v-else class="ri-eye-off-fill align-middle"></i>
                                                            <!-- <i class="ri-eye-fill align-middle"></i> -->
                                                            
                                                            </button>
                                                            <div style="color: red" v-if="v$.member.confirm_password.$error" class="text-sm mt-2 text-red-700"> 
                                                              {{ v$.member.confirm_password.$errors[0].$message }}
                                                            </div>

                                                        </div>
    
                                                        <div class="invalid-feedback">
                                                            กรุณาระบุรหัสผ่านอีกครั้ง
                                                        </div>
                                                    </div>
                                                    </div>
                                                    
                                                    
                                                    <div class="row" >
                                                   
                                                        
                                                        <div class="col-md-6">
                                                            <div class="mb-3">
                                                                    <label class="form-label" for="gen-info-email-input">อีเมล (ถ้ามี)</label>
                                                                    <input
                                                                    v-model="member.email_member"
                                                                    type="text" 
                                                                    class="form-control" id="gen-info-email-input" placeholder="อีเมล" >
                                                                    
                                                                    
                                                               
                                                                </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                            <div class="mb-3">
                                                                    <label class="form-label" for="gen-info-email-input">Line ID (ถ้ามี)</label>
                                                                    <input
                                                                    v-model="member.line_id"
                                                                    type="text" 
                                                                    class="form-control" id="gen-info-email-input" placeholder="Line ID" >
                                                                    
                                                                    
                                                                
                                                                </div>
                                                    </div>
                                                   
                                                    </div>
    
    
                                                    <div class="mt-2 text-center" style="padding-top: 25px;">
                                                        <!-- <p>URLAPI: {{ urlapi }}</p> -->
                                                        
                                                        <button class="btn btn-success w-50" id="regissubmit" type="submit"><strong>ยืนยัน</strong></button>
                                                    </div>
    
    
                                                    
                                                </form>
                                            </div>
                                           
    
                                        </div>
                                    </div>
                                    <!-- end col -->
                                </div>
                                <!-- end row -->
                            </div>
                            <!-- end card -->
                        </div>
                        <!-- end col -->
    
                    </div>
                    <!-- end row -->
                </div>
                <!-- end container -->
            </div>
            <!-- end auth page content -->
    
    
        </div>
    
       
       
         
  
    
    </template>
    
    
    <script>
    
    import axios from "axios";
    // Import Vuelidate
    import useVuelidate from '@vuelidate/core';
    //import { required, email, minLength, sameAs, helpers } from '@vuelidate/validators';
    import { required, minLength,sameAs, helpers } from '@vuelidate/validators';
    //import Datepicker from 'vuejs-datepicker-thai';
    //import http from '@/services/BackendService';

    export default {

      //components: {
       //       Datepicker,
       //  },
      
      data() {
       /* const currentYear = new Date().getFullYear();
        const startYear = 2463;
        const endYear = currentYear + 10;
      
        const years = Array.from(
      { length: endYear - startYear + 1 + futureYears },
      (_, index) => startYear + index
    ).reverse();*/
       
      
        return {
        v$: useVuelidate(),
        provinces: [],
        typemembers: [],
        districts: [],
        subdistricts: [],
        password: '',
        confirm_password:'',
        membertype: '',
        province: '',
        district: '',
        subdistrict: '',
        errors: [],
        nameError: false,
        first_name: '',
        last_name: '',
        imagePath: require('@/assets/loglabai.png'),
        showPassword1: false,
        showPassword2: false,
        mobile_phone_number: '',
        error_chk_phone:'',
        error_chk_cardnumber:'',
        url: process.env.VUE_APP_URL,
        urlapi: process.env.VUE_APP_API_URL,
        days: Array.from({ length: 31 }, (_, index) => index + 1),
        selectedTypemenber: null,
        selectedDay: '',
        selectedMonth: null,
        selectedYear: null,
        id_card_number: null,
        idCardNumberError: null,
        email_member:'',
        line_id:'',

        

        months: [
        { value: 1, label: 'มกราคม' },
        { value: 2, label: 'กุมภาพันธ์' },
        { value: 3, label: 'มีนาคม' },
        { value: 4, label: 'เมษายน' },
        { value: 5, label: 'พฤษภาคม' },
        { value: 6, label: 'มิถุนายน' },
        { value: 7, label: 'กรกฎาคม' },
        { value: 8, label: 'สิงหาคม' },
        { value: 9, label: 'กันยายน' },
        { value: 10, label: 'ตุลาคม' },
        { value: 11, label: 'พฤศจิกายน' },
        { value: 12, label: 'ธันวาคม' },
      ],
        
        years:[],
       
        member: {
            first_name: '',
            last_name: '',
            id_card_number: '',
            mobile_phone_number: '',
            selectedDay: '',
            selectedMonth: '',
            selectedYear: '',
          
            address: '',
            province: '',
            district: '',
            subdistrict: '',
            password: '',
            confirm_password: '',
            email_member:'',
            line_id:'',
            membertype:'',
            
          
           
          }
        };
      },

      validations() {
      return {
        member: {
            first_name: {
            required:helpers.withMessage('กรุณาระบุชื่อ', required), 
          },
          last_name: {
            required:helpers.withMessage('กรุณาระบุนามสกุล', required), 
          },
          id_card_number: {
            required:helpers.withMessage('กรุณาระบุเลขบัตรประจำตัว 13 หลัก', required), 
          },
          mobile_phone_number: {
            required:helpers.withMessage('กรุณาระบุเบอร์โทรศัพท์', required), 
            min:helpers.withMessage('กรุณาระบุเบอร์โทรศัพท์ 10 หลัก', minLength(10)),
            
          },
          selectedDay: {
            required:helpers.withMessage('กรุณาระบุวันเกิด', required), 
          },
          selectedMonth: {
            required:helpers.withMessage('กรุณาระบุวันเกิด', required), 
          },
          selectedYear: {
            required:helpers.withMessage('กรุณาระบุวันเกิด', required), 
          },
          address: {
            required:helpers.withMessage('กรุณาระบุที่อยู่', required), 
          },
         
          
          password: {
              required:helpers.withMessage('กรุณาระบุรหัสผ่าน', required), 
              min:helpers.withMessage('รหัสผ่านความยาวขั้นต่ำ 8 ตัวอักษร', minLength(8)),
            },
        confirm_password: {
              required:helpers.withMessage('กรุณาระบุรหัสผ่านอีกครั้ง', required), 
              sameAs: helpers.withMessage('รหัสผ่านยืนยันไม่ตรงกัน',sameAs(this.member.password))
            }
        },
        province: {
            required:helpers.withMessage('กรุณาระบุจังหวัด', required), 
          },
          district: {
            required:helpers.withMessage('กรุณาระบุอำเภอ', required), 
          },
          subdistrict: {
            required:helpers.withMessage('กรุณาระบุตำบล', required), 
          },
          membertype: {
            required:helpers.withMessage('กรุณาระบุประเภทการลงทะเบียน', required), 
          },

          
          /*username: {
           required:helpers.withMessage('ป้อนชื่อผู้ใช้ก่อน', required), 
           validUsername:helpers.withMessage('ชื่อผู้ใช้เป็นตัวอักษร a-z/A-Z เท่านั้น',validUsername)
          },
          mobile: {
           required:helpers.withMessage('ป้อนเบอร์โทรศัพท์ก่อน', required), 
          },
          email: {
           required:helpers.withMessage('ป้อนอีมเมล์ก่อน', required), 
           email:helpers.withMessage('รูปแบบอีเมล์ไม่ถูกต้อง', email),  
          },
          password: {
            password: {
              required:helpers.withMessage('ป้อนรหัสผ่านก่อน', required), 
              min:helpers.withMessage('รหัสผ่านความยาวขั้นต่ำ 6 ตัวอักษร', minLength(6)),
            },
            confirm_password: {
              required:helpers.withMessage('ป้อนรหัสผ่านยืนยันก่อน', required), 
              sameAs: helpers.withMessage('รหัสผ่านยืนยันไม่ตรงกัน',sameAs(this.password.password))
            }
            
          },*/
        }
    },
    
      mounted() {

        
        // เรียก API เพื่อดึงข้อมูลจังหวัด
        axios.get(process.env.VUE_APP_API_URL+"slectpprovince").then((response) => {
          this.provinces = response.data;
        });

        axios.get(process.env.VUE_APP_API_URL+"selectedYear").then((response) => {
          this.years = response.data;

          
        });

        axios.get(process.env.VUE_APP_API_URL+"slectmembertype").then((response) => {
          this.typemembers = response.data;

          
        });
        
        
      },
      watch: {
        
        province() {
         
          // เมื่อเลือกจังหวัดแล้ว ให้ดึงข้อมูลอำเภอมาแสดง
          axios.get(process.env.VUE_APP_API_URL+"slectaumph/" + this.province).then((response) => {
            this.districts = response.data;
          });
        },
        district() {
          // เมื่อเลือกอำเภอแล้ว ให้ดึงข้อมูลตำบลมาแสดง
          axios.get(process.env.VUE_APP_API_URL+"slectdist/" + this.district).then((response) => {
            this.subdistricts = response.data;
          });
        },
      },
    
    methods: {

      togglePasswordVisibility(field) {
        //alert(this.showPassword1);

        this.showPassword1 = !this.showPassword1;
        this.showPassword2 = !this.showPassword2;
        if (this.$el && this.$el instanceof HTMLElement) {
          const passwordInput = this.$el.querySelector('.password-input'+field);
          if (this.showPassword1) {
            passwordInput.type = 'text';
          } else {
            passwordInput.type = 'password';
          }

          if (this.showPassword2) {
            passwordInput.type = 'text';
          } else {
            passwordInput.type = 'password';
          }
        }

        
    },
    chkphone() {


       
        axios.get(process.env.VUE_APP_API_URL+"chkphone/" + this.member.mobile_phone_number).then((response) => {
            //this.subdistricts = response.data;
            if(response.data>=1){
              this.member.mobile_phone_number = '';
              this.error_chk_phone = "เบอร์มือถือได้ใช้ลงทะเบียนไปแล้ว";
            }else{
              this.error_chk_phone = "";
            }
        });
      
      
      
    },

    chkcard_number() {


       
            axios.get(process.env.VUE_APP_API_URL+"chkcard_number/" + this.member.id_card_number).then((response) => {
                
                if(response.data>=1){
                  this.member.id_card_number = '';
                  this.error_chk_cardnumber = "หมายเลขบัตรประชาชนนี้ได้ใช้ลงทะเบียนไปแล้ว";
                }else{
                  this.error_chk_cardnumber = "";
                }
            });



      },

    /*chkcard_number() {
      
     
        http.post(process.env.VUE_APP_API_URL + 'chkcard_number', {
          id_card_number: this.member.id_card_number
        })
          .then((response) => {
            
            if(response.data>=1){
              this.member.id_card_number = '';
              this.error_chk_cardnumber = "หมายเลขบัตรประชาชนนี้ได้ใช้ลงทะเบียนไปแล้ว";
            }else{
              this.error_chk_cardnumber = "";
            }
              
          })
          .catch((error) => {
              console.error('Error during POST request:', error);
          });

        
        
    },*/

    checkDigilCardID() {
            // ใส่รูปแบบ Regex ที่ต้องการตรวจสอบ
            const idCardRegex = /^\d{13}$/;

            if (this.member.id_card_number.match(idCardRegex)) {
              this.idCardNumberError = ''; // ไม่มีข้อผิดพลาด
            } else {
              this.idCardNumberError = 'หมายเลขบัตรประชาชนไม่ถูกต้อง';
            }

        },

    

    async savedata() {
      try {
        this.v$.$validate() // เช็คตัว input ทุกช่อง
        this.errors = [];
		//var len = this.member.password.length;

        if(!this.v$.$error) {
        
        


    /*if (len < 5 || len > 20) {
					this.nameError = true;
					this.errors.push({
						message: 'Name must be between 5 to 20 characters long.'
                       
					});
                    return false;
				} else {
					document.getElementById('password').className = "form-control is-valid";
					this.errors.push({
						message: 'Validated.'
					});
					document.getElementById('feedback-1').className = "valid-feedback";
				}*/

                
        this.member.province = this.province; // อัปเดตค่าจังหวัดจาก dropdown
        this.member.district = this.district; // อัปเดตค่าอำเภอจาก dropdown
        this.member.subdistrict = this.subdistrict; // อัปเดตค่าตำบลจาก dropdown

        this.member.membertype = this.membertype;
        

        const response = await fetch(process.env.VUE_APP_API_URL+'insertmember', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.member)
        });

        if (response.ok) {
          // ทำอะไรก็ตามที่คุณต้องการหลังจากลงทะเบียนสำเร็จ
          //console.log('ลงทะเบียนสำเร็จ!');
            //alert('ลงทะเบียนสำเร็จ!')
          // this.$swal('Hello Vue world!!!');

          // ลงทะเบียนสำเร็จ
          this.$swal({
            title: 'สำเร็จ!',
            text: 'ลงทะเบียนสำเร็จ',
            icon: 'success',
            confirmButtonText: 'ตกลง'
            // สามารถเพิ่มตัวเลือกอื่น ๆ ตามต้องการ
          }).then(() => {
                this.$router.push('login')
               
            });

        } else {
          // กรณีเกิดข้อผิดพลาด
         // console.error('เกิดข้อผิดพลาดในการลงทะเบียน');

         
         this.$swal({
            title: 'เกิดข้อผิดพลาด',
            text: 'เกิดข้อผิดพลาดในการลงทะเบียน',
            icon: 'error',
            confirmButtonText: 'ตกลง'
            // สามารถเพิ่มตัวเลือกอื่น ๆ ตามต้องการ
          });

        }
        }// endif
        
      } catch (error) {
        //console.error('มีข้อผิดพลาดในการเชื่อมต่อกับ API:', error);
        this.$swal({
          title: 'เกิดข้อผิดพลาด',
          text: 'มีข้อผิดพลาดในการเชื่อมต่อกับ API: ' + error,
          icon: 'error',
          confirmButtonText: 'ตกลง'
          // สามารถเพิ่มตัวเลือกอื่น ๆ ตามต้องการ
        });

      }
    }/// end savedata
  }
};


    
    
    
    </script>
    