<template>
  

                            <div class="card-body" v-if="member_id">
                                   
                                   <!-- Nav tabs -->
                                   <ul class="nav nav-pills nav-custom nav-pills mb-3" role="tablist" style="font-size: 16px;">
                                       <li class="nav-item">
                                         
                                           <router-link to="/profile" class="nav-link"> ข้อมูลส่วนตัว</router-link>
                                       </li>
                                       <li class="nav-item">
                                           <router-link to="/land_issue" class="nav-link"> ปัญหาเรื่องที่ดินทำกิน</router-link>
                                       </li>

                                       <li class="nav-item">
                                           <router-link to="/membercard" class="nav-link">บัตรสมาชิก</router-link>
                                       </li>
                                       <!-- <li class="nav-item">
                                           <a class="nav-link" data-bs-toggle="tab" href="#nav-colored-messages" role="tab">
                                               ร้านค้าที่เข้าร่วมโครงการ
                                           </a>
                                       </li> -->
                                       <li class="nav-item">
                                           <a @click="logout" class="nav-link" data-bs-toggle="tab" href="#" role="tab">
                                            ออกจากระบบ
                                           </a>
                                       </li>
                                       
                                   </ul>
                                   
                               </div><!-- end card-body -->

</template>

<script>
export default {
    name : 'SiteNav',
    data() {
        return {
            
            member_id: localStorage.getItem('member_id'),
            imagePath: require('@/assets/img/logolabai.png'),
        }
    },
    mounted() {
        
        //localStorage.clear();


    },
    methods: {
    logout() {
      localStorage.clear();
      window.location.href = process.env.VUE_APP_PAGE_URL+'login';
    }
  }
}
</script>