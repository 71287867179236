import { createRouter, createWebHistory } from 'vue-router'
// Layout

import Flayout from '@/layout/Fontend.vue'
import BeforeLoginlayout from '@/layout/BeforeLogin.vue'


//import view
//fontend

import HomeView from '@/views/frontend/HomeView.vue'
import AboutView from '@/views/frontend/AboutView.vue'
import RegisterView from '@/views/frontend/RegisterView.vue'
import LoginView from '@/views/frontend/LoginView.vue'
import ProfileView from '@/views/frontend/ProfileView.vue'
import Land_issueView from '@/views/frontend/Land_issueView.vue'
import MembercardView from '@/views/frontend/MembercardView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Flayout,
    children:[
      {
        path:'',
        component:HomeView
      }
    ]
  },
  {
    path: '/about',
    name: 'about',
    component: Flayout,
    children:[
      {
        path:'',
        component:AboutView
      }
    ]
  },
  {
    path: '/register',
    name: 'RegisterView',
    component: Flayout,
    children:[
      {
        path:'',
        component:RegisterView
      }
    ],
    
     meta:{
        title : "ลงทะเบียนสมัครสมาชิกสถาบันบริหารจัดการธนาคารที่ดิน (องค์การมหาชน)",
        discription: "รายละเอียดสมัครมาชิก"
      }
    
  },
  {
    path: '/login',
    name: 'LoginView',
    //component: Flayout,
    children:[
      {
        path:'',
        component:LoginView
      }
    ],
    
     meta:{
        title : "เข้าสู่ระบบสมาชิกสถาบันบริหารจัดการธนาคารที่ดิน (องค์การมหาชน)",
        discription: "รายละเอียดสมัครมาชิก"
      }
    
  },
  {
    path: '/profile',
    name: 'profile',
    component: BeforeLoginlayout,
    children:[
      {
        path:'',
        component:ProfileView
      }
    ],
    
     meta:{
        title : "ระบบสมาชิกสถาบันบริหารจัดการธนาคารที่ดิน (องค์การมหาชน)",
        discription: "รายละเอียดสมัครมาชิก"
      }
    
  },
  {
    path: '/land_issue',
    name: 'Land_issueView',
    component: BeforeLoginlayout,
    children:[
      {
        path:'',
        component:Land_issueView
      }
    ],
    
     meta:{
        title : "ระบบสมาชิกสถาบันบริหารจัดการธนาคารที่ดิน (องค์การมหาชน)",
        discription: "ความเดือดร้อนเรื่องที่ดินทำกิน"
      }
    
  },
  {
    path: '/membercard',
    name: 'MembercardView',
    component: BeforeLoginlayout,
    children:[
      {
        path:'',
        component:MembercardView
      }
    ],
    
     meta:{
        title : "ระบบสมาชิกสถาบันบริหารจัดการธนาคารที่ดิน (องค์การมหาชน)",
        discription: "บัตรสมาชิก บจธ."
      }
    
  },

  
  {
    path: '/:catchAll(.*)',
    name: 'NotFound404',  
     meta:{
        title : "404 ไม่พบหน้านี้",
        discription: "รายละเอียดหน้า 404"
      }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
