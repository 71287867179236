<template>

<div class="row" style="padding-top: 50px;">
                        
                        <div class="col-xxl-12">
                            <div class="card mt-xxl-n5">
                                
                                <div class="card-header">
                                    <ul class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <a class="nav-link active" data-bs-toggle="tab" href="#personalDetails" role="tab" aria-selected="true">
                                                <i class="fas fa-home"></i> <h3>ระบุปัญหาที่ดินทำกิน</h3>
                                            </a>
                                        </li>
                                       

                                    </ul>
                                </div>
                                <div class="card-body p-4">
                                    <div class="tab-content">
                                        <div class="tab-pane active" id="personalDetails" role="tabpanel">
                                            <form @submit.prevent="savedata" id="myform"  class="row g-3 needs-validation" method="post" novalidate>
                                                <div class="row">
                                                    
                                                <div class="row">
                                                    
                                                </div>
                                                <div class="row " style="padding-top: 20px;">
                                                   <div class="col-md-6">
                                                        
                                                       
                                                       <!-- Outlined Styles -->
                                                        <!-- <div class="hstack gap-2 flex-wrap">
                                                            
                                                            <input type="radio" v class="btn-check" name="options-outlined" id="danger-outlined">
                                                            <label class="btn btn-outline-danger" for="danger-outlined">มีปัญหาที่ดินทำกิน</label>

                                                            <input type="radio" class="btn-check" name="options-outlined" id="danger-outlined">
                                                            <label class="btn btn-outline-danger" for="success-outlined">ไม่มีปัญหาที่ดินทำกิน</label>
                                                        </div> -->
                                                       
                                            

                                                        <div class="invalid-feedback">
                                                            กรุณาระบุประเภทปัญหา
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        
                                                    </div>
                                                </div>


                                        <div class="row" style="padding-top: 25px;">
                                            <label for="idcityzen" class="form-label" style="font-size: 18px;">พื้นที่ที่มีปัญหา</label>
                                               
                                            <div class="row" style="padding-top: 25px;">
                                               <div class="col-md-4">
                                                    <label for="validationCustom03" class="form-label">เลือกจังจังหวัด</label>
                                                    <select v-model="province" class="form-control" @change="handleSelectprov">
                                                        
                                                         <option 
                                                         :key="province.PROVINCE_ID" 
                                                         :value="province.PROVINCE_ID"
                                                         v-for="province in provinces"
                                                         
                                                         >
 
                                                                 {{ province.PROVINCE_NAME }}
                                                         </option>
 
                                                         </select>

                                                    <div class="invalid-feedback">
                                                        กรุณาระบุ จังหวัด
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    
                                                    <label for="validationCustom03" class="form-label">เลือกอำเภอ</label>
                                                    <select  v-model="district" class="form-control" @change="handleSelectaump">
                                                        <option :key="district.AMPHUR_ID" :value="district.AMPHUR_ID" v-for="district in districts">
                                                            {{ district.AMPHUR_NAME }}
                                                        </option>
                                                        </select>

                                                    <div class="invalid-feedback">
                                                        กรุณาระบุ อำเภอ
                                                    </div>
                                                    
                                                </div>
                                                    
                                                    <div class="col-md-4">
                                                    
                                                    <label for="validationCustom03" class="form-label">เลือกตำบล</label>
                                                    <select v-model="subdistrict"  class="form-control">
                                                        <option v-bind:key="subdistrict.DISTRICT_ID" :value="subdistrict.DISTRICT_ID" v-for="subdistrict in subdistricts">
                                                            {{ subdistrict.DISTRICT_NAME }}
                                                        </option>
                                                        </select>

                                                    <div class="invalid-feedback">
                                                        กรุณาระบุ ตำบล
                                                    </div>
                                                    
                                                </div>
                                        </div>
                                               
                                               
                                                <div class="invalid-feedback">
                                                        กรุณาระบุ ปัญหาที่ดินทำกิน
                                                    </div>
                                           </div>
                                                    
                                            <div class="row" style="padding-top: 25px;">
                                            <label for="idcityzen" class="form-label" style="font-size: 18px;">ปัญหาที่ดินทำกิน</label>
                                               
                                                <div v-for="(issue1, index) in issue_type" :key="index" class="col-md-4">
                                                    <!-- Switches Color -->
                                                    <div class="form-check form-switch form-switch-md" dir="ltr">
                                                        <label class="form-check-label" :for="'issue_type_id' + issue1.issue_type_id">
                                                        <input 
                                                            v-model="member.issue_type_id" 
                                                            :value="issue1.issue_type_id" 
                                                            :id="'issue_type_id' + issue1.issue_type_id" 
                                                            type="radio" 
                                                            class="form-check-input" 
                                                            name="issue_type_id" 
                                                        >
                                                            {{ issue1.issue_type_name }}
                                                        </label>
                                                    </div>
                                                </div>
                                               
                                               
                                                <div class="invalid-feedback">
                                                        กรุณาระบุ ปัญหาที่ดินทำกิน
                                                    </div>
                                                </div>
                                                    
                                            <div class="row" style="padding-top: 25px;">
                                               
                                                
                                          <label for="idcityzen2" class="form-label" style="font-size: 18px;">ท่านต้องการรับบริการจาก บจธ. ท่านต้องการให้ช่วยเหลือ/ส่งเสริมเรื่องใด</label> 
                                                
                                               
                                                <div 
                                                v-for="(issue, index) in help_type" 
                                                :key="index" class="col-md-4">
                                                    <!-- Switches Color -->
                                                    <div class="form-check form-switch form-switch-md" dir="ltr">
                                                        <label class="form-check-label" :for="'help_type_id' + issue.help_type_id">
                                                        <input
                                                            v-model="member.help_type_id" 
                                                            :value="issue.help_type_id" 
                                                            :id="'help_type_id' + issue.help_type_id" 
                                                            type="checkbox" 
                                                            class="form-check-input" 
                                                            name="help_type_id"
                                                            :checked="isChecked(issue)"
                                                        >
                                                            {{ issue.help_type_name }}
                                                        </label>
                                                    </div>
                                                </div>

                                             
                                                 <!-- <div class="col-md-4">
                                                    
                                                    <div class="form-check form-switch form-switch-md" dir="ltr">
                                                        <input type="checkbox" class="form-check-input" name="want_help" id="want_help7">
                                                        <label class="form-check-label" for="want_help7">แหล่งค้าขายอุปกรณ์การเกษตรที่ราคาถูก</label>
                                                    </div>
                                                </div> -->
                                                
                                            </div>
                                                    
                                            <div class="row" style="padding-top: 25px;">
                                                    
                                                    <label for="idcityzen" class="form-label" style="font-size: 18px;">ท่านรู้จัก บจธ. จากช่องทางใด</label> 
                                                    
                                             </div>
                                                    
                                            <div class="row" style="padding-top: 25px;">
                                               
                                                
                                             <label for="idcityzen" class="form-label" style="font-size: 18px;">รายละเอียดอื่นๆ</label> 
                                                 <div class="col-md-12">
                                                   
                                                    
                                                        <textarea 
                                                        v-if="this.member.member_issue_id" 
                                                        v-model="member.other_description" 
                                                        class="form-control" 
                                                        placeholder="รายละเอียดอื่นๆ" 
                                                        id="des-info-description-input" rows="5">
                                                        </textarea>

                                                        <textarea 
                                                        v-else 
                                                        v-model="member.other_description" 
                                                        class="form-control" 
                                                        placeholder="รายละเอียดอื่นๆ" 
                                                        id="des-info-description-input" rows="5">
                                                        </textarea>
                                                        
                                                        
                                                  
                                                </div>
                                                
                                                
                                            </div>
                                                    
                                           
                                                    
                                                 
                                                  
                                                  
                                                    <div class="col-lg-12" style="padding-top: 10px;">
                                                        <div class="hstack gap-2 justify-content-end">
                                                            <button id="btnSend" type="submit" class="btn btn-primary">บันทึก</button>
                                                            
                                                             <input 
                                                             v-if="this.member.member_issue_id" 
                                                             v-model="member.member_issue_id" 
                                                             type="hidden" name="member_issue_id">

                                                             <input 
                                                             v-model="this.member.member_id" 
                                                             type="hidden" name="member_id">
                                                             
                                                            <!-- <button type="button" class="btn btn-soft-success">Cancel</button>-->
                                                        </div>
                                                    </div>
                                                    <!--end col-->
                                                </div>
                                                <!--end row-->
                                            </form>
                                        </div>
                                        <!--end tab-pane-->
                                        
                                       
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--end col-->
                    </div>
    
</template>

<script>
import axios from "axios";
import useVuelidate from '@vuelidate/core';
import http from '@/services/BackendService'
export default{
    data() {
        return {
            v$: useVuelidate(),
            issue_type: [],
            help_type_id_update: [],
            issue_type_id:null,
            help_type: [],
            help_type_id: [],
            member_issue_id:null,
            member_id:null,
            other_description:null,
            issue_tracking_status_id:null,
            provinces: [],
            districts: [],
            subdistricts: [],
            province: '',
            district: null,
            subdistrict: null,
            getprovince:null,


            member: {
                help_type_id: [],
                issue_type_id:null,
                member_issue_id:null,
                member_id:null,
                other_description:null,
                issue_tracking_status_id:null,
                province: '',
                district: '',
                subdistrict: ''
          
           
          }
            
        }
    },

    mounted() {

            const userData = localStorage.getItem('user');
            const getdata = JSON.parse(userData);
    
            if (userData) {
            // แปลงข้อมูล JSON กลับเป็น object
            this.user = JSON.parse(userData);
            this.member.member_id = getdata.user.member_id;
            this.token = "Bearer "+getdata.user.member_id+"|"+getdata['token'];
            

    }else{
      this.$router.push('login')
    }


    http.post(process.env.VUE_APP_API_URL + 'memberissue', {
            member_id: getdata.user.member_id
      })
        .then((responissue) => {
          
            this.respondata = responissue.data;
            this.member.member_issue_id = this.respondata.member_issue_id;
            //this.member.member_id = this.respondata.member_id;
            this.member.issue_type_id = this.respondata.issue_type_id;
            this.member.other_description = this.respondata.other_description;
            this.member.issue_tracking_status_id = this.respondata.issue_tracking_status_id;

            this.getprovince = this.respondata.PROVINCE_ID;
            this.getdistrict = this.respondata.AMPHUR_ID;
            this.getsubdistrict = this.respondata.DISTRICT_ID;

            
            
            console.log('member.member_issue_id:',  this.member.member_issue_id);


           
            // เรียก API เพื่อดึงข้อมูลจังหวัด
            axios.get(process.env.VUE_APP_API_URL+"slectpprovince")
            .then((response) => {
                
                
                this.provinces = response.data;
                if (this.provinces.length > 0) {
                        this.province = this.getprovince;
                        this.district = this.getdistrict;
                        this.subdistrict = this.getsubdistrict;
                    }
            });

            axios.get(process.env.VUE_APP_API_URL+"slectaumph/" + this.getprovince).then((response) => {
                this.districts = response.data;
            });

            axios.get(process.env.VUE_APP_API_URL+"slectdist/" + this.getdistrict).then((response) => {
                this.subdistricts = response.data;
            });

            

            http.post(process.env.VUE_APP_API_URL + 'member_issue_help_type', {
                 member_issue_id: this.member.member_issue_id
            })
            .then((responissuetype) => {
                
                    this.help_type_id_update = responissuetype.data;

                    
                    console.log('member.help_type_id:',  this.help_type_id_update);
                    
                })
                .catch((error) => {
                    console.error('Error during POST request:', error);
            });
            //end member_issue_help_type

            

           
        })
        .catch((error) => {
            console.error('Error during POST request:', error);
    });


   


         
     axios.get(process.env.VUE_APP_API_URL+"slectissue_type").then((response) => {
          this.issue_type = response.data;
          
        })
        .catch(error => {
        console.error('Error fetching data:', error);
      });

      axios.get(process.env.VUE_APP_API_URL+"slecthelp_type").then((response) => {
          this.help_type = response.data;
          
        })
        .catch(error => {
        console.error('Error fetching data:', error);
      });




    },
    watch: {
        // province() {
         
        //   axios.get(process.env.VUE_APP_API_URL+"slectaumph/" + this.province).then((response) => {
        //     this.districts = response.data;
        //   });
        // },
        // district() {
          
        //   axios.get(process.env.VUE_APP_API_URL+"slectdist/" + this.district).then((response) => {
        //     this.subdistricts = response.data;
        //   });
        // },
      },

    methods: {

    handleSelectprov(){
        axios.get(process.env.VUE_APP_API_URL+"slectaumph/" + this.province).then((response) => {
            this.districts = response.data;
          });
    },
    handleSelectaump(){
        axios.get(process.env.VUE_APP_API_URL+"slectdist/" + this.district).then((response) => {
            this.subdistricts = response.data;
          });
    },

    isChecked(issue) {
        // Implement your logic to determine if the checkbox should be checked
        return this.help_type_id_update.some(issuex => issuex.help_type_id === issue.help_type_id);
    },
    // isSelected(province) {
    //     // Implement your logic to determine if the checkbox should be checked
    //     return this.province === province.PROVINCE_ID;
    // },

    
    async savedata() {
      try {
        this.v$.$validate() // เช็คตัว input ทุกช่อง
        this.errors = [];
		//var len = this.member.password.length;

        if(!this.v$.$error) {
      
        //alert(this.member.member_issue_id);
        let urlsumbit ="";

            if(this.member.member_issue_id){

                 urlsumbit = "updateissue";
            }else{

                 urlsumbit = "insertissue";
            }

            this.member.province = this.province; // อัปเดตค่าจังหวัดจาก dropdown
            this.member.district = this.district; // อัปเดตค่าอำเภอจาก dropdown
            this.member.subdistrict = this.subdistrict; // อัปเดตค่าตำบลจาก dropdown
           

            const response = await fetch(process.env.VUE_APP_API_URL + urlsumbit, {
            method: 'POST',
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                "Authorization": `${this.token}`
            },
            body: JSON.stringify(this.member)
            });
       

        if (response.ok) {
           
          this.$swal({
            title: 'สำเร็จ!',
            text: 'บันทึกสำเร็จ',
            icon: 'success',
            confirmButtonText: 'ตกลง'
            // สามารถเพิ่มตัวเลือกอื่น ๆ ตามต้องการ
            
          }).then(() => {
                //this.$router.push('/land_issue');
                //this.$router.push('/land_issue').catch(() => {});
                this.$router.go(0);
            });
          
          
        } else {
          // กรณีเกิดข้อผิดพลาด
         // console.error('เกิดข้อผิดพลาดในการลงทะเบียน');

         
         this.$swal({
            title: 'เกิดข้อผิดพลาด',
            text: 'เกิดข้อผิดพลาดในการลงทะเบียน',
            icon: 'error',
            confirmButtonText: 'ตกลง'
            // สามารถเพิ่มตัวเลือกอื่น ๆ ตามต้องการ
          });

        }
        }// endif
        
      } catch (error) {
        //console.error('มีข้อผิดพลาดในการเชื่อมต่อกับ API:', error);
        this.$swal({
          title: 'เกิดข้อผิดพลาด',
          text: 'มีข้อผิดพลาดในการเชื่อมต่อกับ API: ' + error,
          icon: 'error',
          confirmButtonText: 'ตกลง'
          // สามารถเพิ่มตัวเลือกอื่น ๆ ตามต้องการ
        });

      }
    }/// end savedata
  
  

        
    } // end methods



}



</script>