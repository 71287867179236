import { createStore } from 'vuex'

export default createStore({
  state: {
    isLoggedIn: localStorage.getItem('isLoggedIn') === 'true',
    user: {
      member_id: localStorage.getItem('member_id') || '',
      first_name: localStorage.getItem('first_name') || '',
      last_name: localStorage.getItem('last_name') || '',
      token: localStorage.getItem('token') || '',
    },
  },
  getters: {
  },
  mutations: {
    login(state, { member_id, token }) {
      state.isLoggedIn = true;
      state.user.member_id = member_id;
      state.user.token = token;
      localStorage.setItem('isLoggedIn', 'true');
      localStorage.setItem('member_id', member_id);
      localStorage.setItem('token', token);
    },
    logout(state) {
      state.isLoggedIn = false;
      state.user.member_id = '';
      state.user.token = '';
      localStorage.setItem('isLoggedIn', 'false');
      localStorage.removeItem('member_id');
      localStorage.removeItem('token');
    },
  },
  actions: {
  },
  modules: {
  }
})
