<template>
    <footer class="footer">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-6">
                    <span v-text="currentYear"></span> © Labai.or.th 
                </div>

            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name : 'PageFooter',
    computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  }
}
</script>