<template>
  
  <html lang="en" data-layout="vertical" data-topbar="light" data-sidebar="dark" data-sidebar-size="lg" data-sidebar-image="none" data-preloader="disable">
    <head>

    <meta charset="utf-8" />
    <!-- <title>Sign In | Velzon - Admin & Dashboard Template</title> -->
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta content="Premium Multipurpose Admin & Dashboard Template" name="description" />
    <meta content="Themesbrand" name="author" />
    <!-- App favicon -->
    <link rel="shortcut icon" href="assets/images/favicon.ico">

    <!-- Layout config Js -->
    <!-- <script src="assets/js/layout.js"></script> -->
    <!-- Bootstrap Css -->
    <link rel="stylesheet" href="assets/css/bootstrap.min.css" type="text/css">
    <link rel="stylesheet" href="assets/css/icons.min.css" type="text/css">
    <link rel="stylesheet" href="assets/css/app.min.css" type="text/css">
    <link rel="stylesheet" href="assets/css/custom.min.css" type="text/css">

  </head>
  
  
  <body>

<!-- auth-page wrapper -->
<div style="
background-color: transparent; 
background:linear-gradient(-45deg,#198754 50%,#ebed7b);
"
 class="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
    <div class="bg-overlay"></div>
    <!-- auth-page content -->
    <div class="auth-page-content overflow-hidden pt-lg-5">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card overflow-hidden">
                        <div class="row g-0">
                            <div class="col-lg-6">
                                <div class="p-lg-5 p-4 auth-one-bg h-100">
                                    <div class="bg-overlay"></div>
                                    <div class="position-relative h-100 d-flex flex-column">
                                        <div class="mb-4">
                                            <a href="index.html" class="d-block">
                                                
                                                <img v-bind:src="imagePath" style="height: 80px; width: 70px;" />
                                            </a>
                                        </div>
                                        <div class="mt-auto">
                                            <div class="mb-3">
                                                <i class="ri-double-quotes-l display-4 text-success"></i>
                                            </div>

                                            <div id="qoutescarouselIndicators" class="carousel slide" data-bs-ride="carousel">
                                                <div class="carousel-indicators">
                                                    <button type="button" data-bs-target="#qoutescarouselIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                                    <button type="button" data-bs-target="#qoutescarouselIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                                    <button type="button" data-bs-target="#qoutescarouselIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                                </div>
                                                <div class="carousel-inner text-center text-white-50 pb-5">
                                                    <div class="carousel-item active">
                                                        <p class="fs-15 fst-italic">" สถาบันบริหารจัดการธนาคารที่ดิน (องค์การมหาชน) "</p>
                                                    </div>
                                                    <div class="carousel-item">
                                                        <p class="fs-15 fst-italic">" การกระจายการถือครองที่ดินการตรวจสอบ การใช้ประโยชน์ที่ดินการป้องกันการสูญเสียสิทธิ"</p>
                                                    </div>
                                                    <div class="carousel-item">
                                                        <p class="fs-15 fst-italic">" สร้างกลไกในการบริหารจัดการที่ดินด้วยการจัดตั้งธนาคารที่ดิน "</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- end carousel -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- end col -->

                            <div class="col-lg-6">
                                <div class="p-lg-5 p-4">
                                    <div>
                                        <h2 class="text-primary">เข้าสู่ระบบสมาชิก บจธ</h2>
                                        <p class="text-muted">สถาบันบริหารจัดการธนาคารที่ดิน (องค์การมหาชน)</p>
                                    </div>

                                    <div class="mt-4">
                                      <form @submit.prevent="onSubmit">

                                            <div class="mb-3">
                                                <label for="mobile_phone_number" class="form-label">เบอร์โทรศัพท์</label>
                                                <input v-model="mobile_phone_number" type="text" class="form-control" name="mobile_phone_number" id="mobile_phone_number" placeholder="เบอร์โทรศัพท์">
                                                <div v-if="v$.mobile_phone_number.$error" class="text-sm mt-2 text-danger">
                                                  {{ v$.mobile_phone_number.$errors[0].$message }}
                                                </div>
                                                                            
                                            </div>

                                            <div class="mb-3">
                                                <!-- <div class="float-end">
                                                    <a href="auth-pass-reset-cover.html" class="text-muted">Forgot password?</a>
                                                
                                                </div> -->
                                                <label class="form-label" for="password-input">รหัสผ่าน</label>
                                                <div class="position-relative auth-pass-inputgroup mb-3">
                                                    <input v-model="password" type="password" class="form-control pe-5 password-input" name="password" placeholder="รหัสผ่าน" id="password">
                                                    <!-- <button class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon"><i class="ri-eye-fill align-middle"></i></button> -->
                                                    <div v-if="v$.password.$error" class="text-sm mt-2 text-danger">
                                                    {{ v$.password.$errors[0].$message }}
                                                  </div>
                                                
                                                </div>
                                            </div>

                                            

                                            <div class="mt-4">
                                                <button @click="submitForm" class="btn btn-success w-100" type="submit">เข้าสู่ระบบ</button>
                                            </div>

                                            

                                        </form>
                                    </div>

                                    <div class="mt-5 text-center">
                                        <p class="mb-0">ลงทะเบียนเป็นสมาชิก บจธ. 
                                          <router-link to="/register" 
                                          class="block px-4 py-2 font-medium hover:text-indigo-900 lg:inline-block">
                                                            
                                                <button class="btn btn-primary w-50" type="submit">ลงทะเบียน</button>
                                        </router-link>
                                      </p>
                                    </div>
                                </div>
                            </div>
                            <!-- end col -->
                        </div>
                        <!-- end row -->
                    </div>
                    <!-- end card -->
                </div>
                <!-- end col -->

            </div>
            <!-- end row -->
        </div>
        <!-- end container -->
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <footer class="footer">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center">
                        <p class="mb-0">&copy;
                          สถาบันบริหารจัดการธนาคารที่ดิน (องค์การมหาชน) <span v-text="currentYear"></span>  © Labai.or.th
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!-- end Footer -->
</div>
<!-- end auth-page-wrapper -->


</body>
  
  </html>

  </template>

  <script>
 
  import useVuelidate from '@vuelidate/core';
  import { required, minLength, helpers } from '@vuelidate/validators';
  import http from '@/services/AuthService'
export default {
    name : 'PageLogin',
    data(){
    return {
      v$: useVuelidate(),
      mobile_phone_number: '',
      password: '',
      imagePath: require('@/assets/loglabai.png'),
    }
  },
  methods:{
    submitForm(){
      // alert(id)
      this.v$.$validate(); // checks all input
      if(!this.v$.$error){
        // ถ้า validate ผ่านแล้ว
        // alert('Form validate Success')
        // เรียกใช้งาน API Login จาก Laravel
       
        http.post('checklogin',
          {
            "mobile_phone_number": this.mobile_phone_number,
            "password": this.password
          }
        ).then(response => {

          console.log(response.data)

          // เก็บข้อมูล user ลง localStorage
          localStorage.setItem('user', JSON.stringify(response.data))

          // เมื่อล็อกอินผ่านส่งไปหน้า profile
          this.$router.push('profile')
          //window.location.href = '/profile';

        }).catch(error => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        })


      }else{
        // alert('Form validate fail!')
      }
    }
  },
  validations() {
    return {
      mobile_phone_number: { 
        required: helpers.withMessage('กรุณาระบุเบอร์โทรศัพท์', required),
        
      } ,
      password: { 
        required: helpers.withMessage('กรุณาระบุรหัสผ่าน', required), 
        minLength : helpers.withMessage(
          ({
            $params
          }) => `รหัสผ่านต้องไม่น้อยกว่า ${$params.min} ตัวอักษร`, 
          minLength(6)
        )
      }
    }
  },
    computed: {
    currentYear() {
      return new Date().getFullYear();
    },
    
  }
}
</script>